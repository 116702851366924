
import React from 'react';
import "./App.css";

const App = () => {
  return (
    <div className="App">
      <header className="App-header">
        <div className="Banner-container">
          <img src="/banner3.png" className="App-logo" alt="logo" />
        </div>

        <div className="Main-text-box">
          <p>
            Join the SEICAT Army
          </p>
          <div className="Social-icons">
            <a href="https://x.com/seicattoken?s=21">
              <img src="/logo-white.png" alt="" />
            </a>
            <a href="https://discord.gg/TxdNZ5caYP">
              <img src="/discord-mark-white.png" alt="" />
            </a>
            <a href="https://medium.com/@seicat">
              <img src="/Medium-Symbol-White-RGB@2x.png" alt="" />
            </a>
            <a href="https://t.me/+Ei4r0x39U_8zNTBk">
              <img src="Logo.png" alt="" />
            </a>
          </div>
        </div>
      </header>
    </div>
  );
};

export default App;
